import React, { useState } from 'react';

const PlaylistManager = ({ playlists, onAddToPlaylist }) => {
  const [selectedPlaylist, setSelectedPlaylist] = useState('');

  const handleAddToPlaylist = (video) => {
    onAddToPlaylist(selectedPlaylist, video);
  };

  return (
    <div className="flex justify-center items-center mt-4">
    <select
      onChange={(e) => setSelectedPlaylist(e.target.value)}
      className="border border-gray-300 rounded px-4 py-2 mr-2"
    >
      {playlists.map((playlist) => (
        <option key={playlist.id} value={playlist.id}>
          {playlist.snippet.title}
        </option>
      ))}
    </select>
    <button
      onClick={handleAddToPlaylist}
      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
    >
      Añadir a la lista de reproducción
    </button>
  </div>
  );
};

export default PlaylistManager;