import React from 'react';

const Login = ({ onLogin }) => {
  const handleLogin = () => {
    // Lógica para iniciar sesión en YouTube
    onLogin();
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-900">
      <button
        onClick={handleLogin}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
      >
        Iniciar sesión en YouTube
      </button>
    </div>
  );
};

export default Login;