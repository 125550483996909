import React, { useState } from 'react';
import Login from './components/Login';
import Search from './components/Search';
import VideoList from './components/VideoList';
import VideoPlayer from './components/VideoPlayer';
import PlaylistManager from './components/PlaylistManager';
import Pagination from './components/Pagination'
import Header from './components/Header'

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageToken, setNextPageToken] = useState('');
  const videosPerPage = 15;

  const handleLogin = () => {
    setIsLoggedIn(true);
    // Lógica para obtener listas de reproducción del usuario
  };

  const handleResults = (results, nextPageToken) => {
    setVideos(results);
    setNextPageToken(nextPageToken);
  };

  const handleSelectVideo = (video) => {
    setSelectedVideo(video);
  };

  const handleAddToPlaylist = (playlistId, video) => {
    // Lógica para añadir video a la lista de reproducción
  };

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (pageNumber > Math.ceil(videos.length / videosPerPage)) {
      // Lógica para obtener más resultados de YouTube
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <>
          <Header/>
          <Search onResults={handleResults} nextPageToken={nextPageToken} />
          <VideoPlayer video={selectedVideo} />
          <VideoList videos={currentVideos} onSelect={handleSelectVideo} />
          <PlaylistManager playlists={playlists} onAddToPlaylist={handleAddToPlaylist} />
          <Pagination
            videosPerPage={videosPerPage}
            totalVideos={videos.length}
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default App;