import React from 'react';

const VideoList = ({ videos, onSelect }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {videos.map((video) => (
        <div
          key={video.id.videoId}
          onClick={() => onSelect(video)}
          className="cursor-pointer bg-gray-800 text-white p-4 rounded hover:bg-gray-700"
        >
          <h3 className="text-lg font-semibold">{video.snippet.title}</h3>
          <img
            src={video.snippet.thumbnails.default.url}
            alt={video.snippet.title}
            className="w-full mt-2 rounded"
          />
        </div>
      ))}
    </div>
  );
};

export default VideoList;