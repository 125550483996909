import React from 'react';

const VideoPlayer = ({ video }) => {
  if (!video) return null;

  const videoSrc = `https://www.youtube.com/embed/${video.id.videoId}`;

  return (
    <div className="flex justify-center mt-4">
      <iframe
        src={videoSrc}
        frameBorder="0"
        allowFullScreen
        title="Video player"
        className="w-full md:w-3/4 lg:w-3/4 h-64 md:h-96 lg:h-[42rem]"
      />
    </div>
  );
};

export default VideoPlayer;