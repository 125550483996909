import React, { useState } from 'react';
import axios from 'axios';
import API_KEY from '../config';

const Search = ({ onResults, nextPageToken }) => {
    const [query, setQuery] = useState('');
  
    const handleSearch = async (pageToken = '') => {
        console.log('api key : ' + API_KEY);
        try {
            const response = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
                params: {
                  part: 'snippet',
                  q: query,
                  type: 'video',
                  key: API_KEY,
                  maxResults: 100,
                  pageToken: pageToken,
                },
              });
              onResults(response.data.items, response.data.nextPageToken);
        } catch (error) {
            console.error('Error fetching data from YouTube API:', error);
        }
      
    };
  
    return (
      <div className="flex justify-center items-center mt-4">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar videos"
          className="border border-gray-300 rounded px-4 py-2 mr-2 text-gray-700"
        />
        <button
          onClick={() => handleSearch()}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Buscar
        </button>
      </div>
    );
  };

export default Search;